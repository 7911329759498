<div *tuiLet="isOutStaffer$ | async as isOutStaffer" class="widgets">
  <div class="widgets__col">
    <employee-individual class="widget"></employee-individual>

    <employee-budget
      *ngIf="!hiddenWidgets.budget"
      class="widget"
      (noContent)="hiddenWidgets.budget = true"
    ></employee-budget>

    <employee-working-status class="widget"></employee-working-status>

    <app-electronic-document-management
      *ngIf="isShowKedoWidget$ | async"
      class="widget"
    ></app-electronic-document-management>

    <employee-documents
      *ngIf="!isOutStaffer"
      class="widget"
    ></employee-documents>

    <employee-sd-requests
      *ngIf="!isOutStaffer"
      class="widget"
    ></employee-sd-requests>
  </div>

  <div class="widgets__col">
    <employee-learning
      *ngIf="!isLearningPassed"
      class="widget"
    ></employee-learning>

    <employee-extra class="widget"></employee-extra>

    <hobbies class="widget"></hobbies>

    <employee-extra-vaccine class="widget"></employee-extra-vaccine>

    <employee-favorites class="widget"></employee-favorites>

    <employee-signature
      *ngIf="isSignatureAvailable$ | async"
      class="widget"
    ></employee-signature>

    <employee-ip-phone *ngIf="!isOutStaffer" class="widget"></employee-ip-phone>

    <employee-devices *ngIf="!isOutStaffer" class="widget"></employee-devices>
  </div>
</div>
