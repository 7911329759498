<div class="alert"></div>
<div class="header">
  <div class="header__title">Мой бюджет</div>
</div>
<div *tuiLet="budgetReports$ | async as budgetReports" class="content">
  <div *ngIf="budgetReports !== null" class="reports">
    <a
      *ngFor="let report of budgetReports"
      tuiLink
      iconAlign="left"
      icon="tuiIconExternal"
      target="_blank"
      [href]="report.urlReport"
    >
      {{ report.title }}
    </a>

    <div *ngIf="budgetReports.length === 0" class="no-content">
      Вам недоступен ни&nbsp;один отчёт
      <!-- TODO: Как получить доступ к отчётам? Исходим из содежримого, нет записей - нет блока -->
    </div>
  </div>
  <div *ngIf="budgetReports === null" class="skeleton">
    <div class="tui-skeleton tui-skeleton_text"></div>
    <div class="tui-skeleton tui-skeleton_text tui-skeleton_short"></div>
  </div>
</div>
<div class="footer"></div>
