import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';

import {
  ApiGETListRequestParams,
  ApiListResponse,
  ApiResponse,
  BudgetReportDto,
  HttpAnyParams,
  UserBudgetDto,
} from '../interfaces';
import {HOME_API_URL} from '../tokens';

/** TODO: Что-то непонятное на бэке с сортировкой по разным полям  */
interface RegUnitFilterParams {
  name?: string;
  orderByIdAsc?: boolean | null;
  orderByNameAsc?: boolean | null;
  orderByTitleAsc?: boolean | null;
}

type RegUnitGETParams = ApiGETListRequestParams & RegUnitFilterParams & HttpAnyParams;

@Injectable({providedIn: 'root'})
export class UserBudgetService {
  constructor(
    @Inject(HOME_API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  /**
   * Получить список доступных отчётов теекущего пользователя
   *
   * TODO: Неверно указана модель ответа в сваггере
   *
   * TODO: Не приходит meta
   */
  getCurrentReports() {
    return this.http.get<ApiListResponse<readonly UserBudgetDto[]>>(
      `${this.apiUrl}/v1/userBudget/currentUser`,
    );
  }

  /**
   * Получить все записи о существующих связках
   * пользователь-отчёт (права)
   */
  getAll(rawParams: RegUnitGETParams) {
    const params = new HttpParams({fromObject: rawParams});

    return this.http.get<ApiListResponse<readonly BudgetReportDto[] | null>>(
      `${this.apiUrl}/v1/userBudget`,
      {
        params,
      },
    );
  }

  /** Получить запись пользователь-отчёт */
  getById(id: number) {
    return this.http.get<ApiResponse<Readonly<BudgetReportDto> | null>>(
      `${this.apiUrl}/v1/userBudget/${id}`,
    );
  }

  /** Удаление связки пользователь-отчёт (удаление прав) */
  deleteById(id: number) {
    return this.http.delete<boolean>(`${this.apiUrl}/v1/userBudget/${id}`);
  }

  /**
   * Создать связку `пользователь/unit - отчёт`
   *
   * TODO: На бэке запись создается даже с пустыми значениями (подставляется рандомный юзер)
   */
  create(body: {
    /** Формат `main//blabla` */
    login?: string | null;
    positionId?: number | null;
    unitId?: number | null;
    budgetReportId: number;
  }) {
    return this.http.post<ApiResponse<Readonly<BudgetReportDto> | null>>(
      `${this.apiUrl}/v1/userBudget`,
      body,
    );
  }

  /** Обновить запись пользователь-отчёт */
  update(body: {
    /** Формат `main//blabla` */
    login?: string | null;
    positionId?: number | null;
    unitId?: number | null;
    budgetReportId: number;
    id: number;
  }) {
    return this.http.put<ApiResponse<Readonly<BudgetReportDto> | null>>(
      `${this.apiUrl}/v1/userBudget`,
      body,
    );
  }
}
