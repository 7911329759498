import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
} from '@angular/core';
import {pluck} from 'rxjs';
import {startWith, tap} from 'rxjs/operators';
import {UserBudgetService} from 'src/app/home-api';

@Component({
  selector: 'employee-budget',
  templateUrl: './budget.template.html',
  styleUrls: ['./budget.styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeBudgetComponent {
  @Output() noContent = new EventEmitter<boolean>();

  readonly budgetReports$ = this.userBudgetService.getCurrentReports().pipe(
    pluck('data'),
    tap(reports => {
      if (reports !== null && reports.length === 0) {
        this.noContent.emit(true);
      }
    }),
    startWith(null),
  );

  constructor(
    @Inject(UserBudgetService) private readonly userBudgetService: UserBudgetService,
  ) {}
}
